.main {
    min-height: 100vh;
    position: relative;
}

.sidebar {
    display: flex;
    flex-direction: row;
    height: 75px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
}

.sidebar div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    font-size: 0.85em;
    font-family: 'Montserrat';
    font-weight: 700;
}


@media(min-width: 1024px) {
    .sidebar {
        flex-direction: column;
        min-height: 100vh;
        width: 16%;
        align-items: flex-start;
        border-right: 1px solid #eee;
        border-bottom: none;
        position: absolute;
        justify-content: flex-start;
        padding-top: 5%;
    }

    .sidebar div {
        box-sizing: border-box;
        padding: 2%;
        width: 100%;
        transition: all ease 0.3s;
        text-align: left;
        justify-content: flex-start;
        height: 50px;
        padding-left: 20%;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        color: #333;
    }

    .sidebar div:hover {
        background-color: #eee;
        cursor: pointer;
    }

    .content {
        text-align: left;
        width: 80%;
        margin-left: 20%;
        min-height: 100vh;
        margin-top: 0;
        position: relative;
        background-image: url(../../Assets//watermark.svg);
        background-repeat: no-repeat;
        background-position: 447% 197%;
        background-size: 90% 90%;
    }
}