.footerContainer {
    background-color: #E62C4C;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    padding: 25px;
    font-family: 'Montserrat';
}

.footerContainer div {
    margin-bottom: 25px;
    text-align: center;
}

.icon {
    font-size: 1.5em;
    cursor: pointer;
}

@media(min-width:1024px) {
    .footerContainer {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}