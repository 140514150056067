.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
}

.main {
    width: 80%;
    height: 90vh;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 900;
    position: relative;
}

.closeBtn {
    position: absolute;
    background-color: #E62C4C;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    top: -5%;
    right: -5%;
    transition: all ease 0.3s;
    cursor: pointer;
}

.closeBtn:hover {
    background-color: #A52F43;
}

.main button:disabled {
    background-color: #979797;
    cursor: not-allowed;
}

.content {
    position: absolute;
    width: 70%;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    height: 50%;
}

@media(min-width: 1024px) {
    .main {
        width: 45%;
        height: 80vh;
    }

    .closeBtn {
        top: -7%;
        right: -2%;
    }
}