.home {
    height: 70vh;
    background-color: #E62C4C;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_cotainer{
    align-self: center;
}

.scrolltodown {
    position: absolute;
    bottom: -20px;
    left: 50%;
    height: 40px;
    width: 40px;
    transform:  translateY(-80px) translateX(-50%) rotate(45deg);
}

.scrolltodown span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-bottom: 4px solid #E62C4C;
    border-right: 4px solid #E62C4C;
    animation: animate 1.5s linear infinite;
    opacity: 0;
}

.scrolltodown span:nth-child(1){
    transform: translate(-15px, -15px);
    animation-delay: -0.4s;
}

.scrolltodown span:nth-child(2){
    transform: translate(0, 0);
    animation-delay: -0.2s;
}

.scrolltodown span:nth-child(3){
    transform: translate(15px, 15px);
    animation-delay: 0s;
}

@keyframes animate {
    0% {
        top: -5px;
        left: -5px;
        opacity: 0;
    }
    25% {
        top: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: 5px;
        left: 5px;
        opacity: 0;
    }
}
