.login {
    min-height: 100vh;
    display: flex;
}

.container {
    align-self: center;
    background-color: white;
    height: 150px;
    width: 100%;
    display: block;
}

.form {
    display: inline-block;
}
