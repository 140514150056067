.card {
    position: relative;
    width: 25%;
    height: 200px;
    background-color: #FFB89F;
    border-radius: 25px;
    margin: 2%;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
}