.btn {
    font-family: 'Montserrat';
    font-weight: 600;
    border: none;
    background-color: #A52F43;
    color: white;
    padding: 15px;
    padding-right: 75px;
    padding-left: 75px;
    margin-top: 55px;
    box-shadow: 10px 10px 7px 0px #b3223ac2;
}

.btn:hover {
    cursor: pointer;
}

.btn:disabled {
    box-shadow: none;
    background-color: #eee;
    cursor: not-allowed;
}
