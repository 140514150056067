.main {
    padding: 5%;
}

.photo {
    width: 200px;
    height: 200px;
    float: none;
}

.photoContainer div {
    color: #bbb;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.label,
.input {
    display: block;
}

.label {
    text-align: left;
    color: #E62C4C;
}

.input {
    border: none;
    border-bottom: 1px solid #E62C4C;
    width: 100%;
    padding: 10px 15px;
}

select {
    border: none;
    border-bottom: 1px solid #E62C4C;
    width: 100%;
    padding: 10px 15px;
    background-color: transparent;
}

select:disabled {
    cursor: not-allowed;
}

.input:read-only {
    cursor: not-allowed;
}

.field {
    margin-top: 5%;
    font-family: 'bb-book-mono';
}

.text {
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-bottom: 1px solid #E62C4C;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
    cursor: not-allowed;
}

@media(min-width: 1024px) {
    .photoContainer {
        float: left;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .input, .text, select {
        width: 50%;
    }

    .field {
        margin-top: 5%;
        padding-left: 300px;
    }

}