.main {
    padding: 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.card {
    width: 100%;
    height: 200px;
    background-color: #FFB89F;
    position: relative;
    padding: 5%;
    border-radius: 25px;
    margin: 2%;
    background-image: url(../../Assets//Ojito.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    text-align: left;
}

.card .title {
    font-size: 1.65em;
    color: #A52F43;
    font-weight: bold;
}

.card .div {
    margin-top: 2%;
}

.card .delete {
    color: #A52F43;
    font-weight: bold;
    position: absolute;
    bottom: 5%;
    right: 5%;
    cursor: pointer;
}

.btn {
    padding: 10px 15px;
    background-color: #A52F43;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
}

.cancel {
    position: absolute;
    bottom: 5%;
    right: 10%;
    font-weight: 700;
    color: #A52F43;
    cursor: pointer;
}

@media(min-width: 1024px) {
    .main {
        flex-direction: row;
        position: relative;
    }
    .card {
        width: 300px;
        padding: 3%;
    }
}