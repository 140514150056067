:root {
  --black: #000000;
  --white: #FFFFFF;
  --lightGray: #979797;
  --darkGray: #4A4A4A;
  --red: #E62C4C;
  --darkRed: #A52F43;
  --lightRed: #EF8F8E;
  --orange: #FF952D;
  --lightOrange: #FFB89F;
}

.MuiOutlinedInput-root.Mui-focused  
.MuiOutlinedInput-notchedOutline{
  border-color: #E62C4C !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #E62C4C !important;
  font-size: 18px;
}

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.MuiIconButton-colorSecondary {
  color: #ccc !important;
}

.MuiInputBase-input{
  font-family: Montserrat !important;
}