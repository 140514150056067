body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'bb-book-mono';
  src: url('./fonts/bb-book-mono.eot?#iefix') format('embedded-opentype'),  url('./fonts/bb-book-mono.otf')  format('opentype'),
	     url('./fonts//bb-book-mono.woff') format('woff'), url('./fonts/bb-book-mono.ttf')  format('truetype'), url('./fonts/bb-book-mono.svg#bb-book-mono') format('svg');
  font-weight: normal;
  font-style: normal;
}

.MuiStepLabel-label.MuiStepLabel-active,
.MuiStepLabel-label.MuiStepLabel-completed,
.MuiStepLabel-label {
  color: #333 !important;
  font-weight: bold !important;
  text-align: left !important;
  font-size: 1.75em !important;
}

.MuiStepIcon-text {
  font-family: 'bb-book-mono' !important;
  font-weight: 700;
}
