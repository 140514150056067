.main {
    background-color: #E62C4C;
    color: white;
    height: 80px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 0px;
    font-family: 'bb-book-mono';
    font-size: .9rem;
}

.content {
    display: none;
}

.mobileContent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.nosotrosContainer,
.loginContainer {
    padding: 15px;
}

.nosotrosContainer div {
    padding: 10px;
    border-bottom: 1px solid #FFB89F;
    cursor: pointer;
}

.nosotrosContainer .lastElement {
    border-bottom: none;
}

@media(min-width: 1024px) {
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .mobileContent {
        display: none;
    }
}